
require('./others/bootstrap');
require('./common/jquery.min');
require('./common/sticky.min');
require('./common/jquery.plugin.min');
require('./common/imagesloaded.pkgd.min');
require('./common/owl.carousel.min');
require('./common/jquery.countdown.min');
require('./common/jquery.zoom.min');
require('./common/jquery.magnific-popup.min');
require('./common/parallax.min');
require('./common/popup.script');
require('./common/script');
