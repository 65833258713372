require('./main');
import * as $ from 'jquery'


function alert(message = null, title = 'Erreur') {
    message = message ? message : 'Une erreur est survenue. Veuillez réessager plus tard.';
    Wolmart.Minipopup.open({ template:
            `<div class="alert alert-icon alert-error alert-bg alert-inline"><h4 class="alert-title">
                <i class="w-icon-times-circle"></i>${title} </h4> ${message}
            </div>`});
}

function updateQuantity(quantity, link, onSuccess = () => {}, onError = () => {}) {
    axios.patch(link,  {quantity}).then(onSuccess).catch(onError)
}

function updateItemInCartByHref(link, quantity) {
    $('.product-cart')
        .find(`a[href='${link}']`)
        .parents('.product-name')
        .siblings('.price-box')
        .find('.product-quantity')
        .text(quantity)
}

function onError(error, fallback = null) {
    let showedErr = [ 422 ];
    if (error.response) {
        if (showedErr.includes(error.response.status) && error.response.data.errors) {
            alert(error.response.data.errors);
            return;
        }
        alert(fallback);
    }
}

// Update the billing form paiment mode
$('.checkout-form .payment-methods .card-header a').on('click', function () {
    $('.checkout-form .payment-methods ').find('input[name="round"]').val($(this).data('value'));
});

$('.product-qty-form, .prevent-auto-submit').find('.quantity-minus, .quantity-plus').on('click', function(e) {
    e.preventDefault();
});

// Open a tab from foreign button
$('a.tab-nav-opener').on('click' , function (e) {
    $(`a[href="${$(this).attr('href')}"].nav-link`).click();
});

// Trigger checkbox filter items
$('.filter-items.item-check li').on('click',  function () {
    window.location.href = $(this).find('a').attr('href');
});

// Trigger select filter items
$('select.filter-items').on('change',  function () {
    window.location.href = $(this).find('option:selected').data('href');
});

$('.btn-add-cart').on('click', function (e) {
    e.preventDefault();
    let trigger = $(this);

    if (trigger.hasClass('w-icon-cart')) {
        trigger.addClass("load-more-overlay loading");
    }

    if (trigger.hasClass('added')) {
        axios.delete(trigger.data('routeDelete')).then(() => {
            if (trigger.hasClass('w-icon-cart')) {
                trigger.removeClass("load-more-overlay loading");
            }
            trigger.toggleClass("added");
            window.location.reload();
        });
    }
    else {
        axios.post(trigger.data('routeAdd'),  {
            id: trigger.data('productId'),
            quantity: trigger.data('productQuantity') ?? 1,
        }).then(() => {
            if (trigger.hasClass('w-icon-cart')) {
                trigger.removeClass("load-more-overlay loading");
            }
            trigger.toggleClass("added");
            window.location.reload();
        })
    }
});


$('.product-quantity-update').find('.quantity-minus, .quantity-plus').on('click', function () {
    let input = $(this).siblings('input');
    let prevVal = input.val();
    let updateLink = $(this).data('routeUpdate');
    if ($(this).hasClass('w-icon-plus') && parseInt(prevVal) < parseInt(input.attr('max'))) {
        input.val(parseInt(prevVal) + 1);
    }
    else if($(this).hasClass('w-icon-minus') && parseInt(prevVal) > 1)  {
        input.val(parseInt(prevVal) - 1);
    }
    _.debounce(function () {
        updateQuantity(parseInt(input.val()), updateLink, ({ data }) => {
            input.parents('.product-quantity').siblings('.product-subtotal').find('.amount').text(data.subTotal);
            $('.cart-total-value').text(data.total_cart);
            updateItemInCartByHref(input.parents('.product-quantity').siblings('.product-thumbnail').find('a').attr('href'), input.val())
        }, (response) => {
            input.val(prevVal);
            onError(response, 'La mise a jour du prix de l\'article a echoue. Veuillez reessayer plus tard.');
        });
    }, 300)();

});

$(document).ready(function() {
    let $popup = $('.offer-gallery').magnificPopup({
        delegate: 'a',
        type: 'image',
        tLoading: 'Chargement des images #%curr%...',
        mainClass: 'mfp-img-mobile',
        gallery: {
            enabled: true,
            navigateByImgClick: false,
            preload: [0,1] // Will preload 0 - before current, and 1 after the current image
        },
        image: {
            tError: '<a href="%url%">L\'image #%curr%</a> n\'a pas pu être chargé.',
            titleSrc: function(item) {
                return `<a href="${item.el.attr('data-detail')}">` + item.el.attr('title') + `<small>${item.el.attr('data-description')}</small></a>`;
            }
        },
        callbacks: {
            change: function () {
                let img = $(this.content[0]).find('img');
                img.one('click', function () {
                    window.open($('.offer-gallery').find(`a[href='${img.attr('src')}']`).data('detail'), '_blank');
                })
            },
        }
    });

    if ($('main').hasClass("show-popup-offers")) {
        $popup.magnificPopup('open');
    }

    const pageTitle = $('.tab-title');
    const appName = document.title;
    if (pageTitle.length) {
        document.title = `${pageTitle.text()} - ${appName}`;
    }
});


